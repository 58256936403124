import React, { useContext, useRef, useState } from "react"
import RatingComponent from "../ratings/ratings"
import InfoModal from "../infoModal/InfoModal"
import axios from "axios"
import { URLS } from "../../config/urls"
import moment from "moment"
import { userContext } from "../../context/userContext/userContext"

const SubmitReview = ({ salonId, updateReviews }) => {
  const userCtx = useContext(userContext)
  const [error, setError] = useState(null)
  const reviewRef = useRef(null)
  const [cleanlinessRating, setCleanlinessRating] = useState(0)
  const [ambienceRating, setAmbienceRating] = useState(0)
  const [staffRating, setStaffRating] = useState(0)

  console.log(cleanlinessRating, ambienceRating, staffRating);

  return (
    <>
      <InfoModal error={error} setError={setError}/>
      {
        userCtx.user ? <>
            <div className="uk-grid uk-child-width-1-1 uk-child-width-1-3@m uk-child-width-1-2@s uk-grid-divider"
                 data-uk-grid>
              <div className="uk-text-primary">
                Rate Cleanliness
                <div className="mv2">
                  <RatingComponent
                    id={"service"}
                    setValue={setCleanlinessRating}/>
                </div>
              </div>
              <div className="uk-text-primary">
                Rate Ambience
                <div className="mv2">
                  <RatingComponent
                    id={"stylist"}
                    setValue={setAmbienceRating}/>
                </div>
              </div>
              <div className="uk-text-primary">
                Rate Staff
                <div className="mv2">
                  <RatingComponent
                    id={"venue"}
                    setValue={setStaffRating}/>
                </div>
              </div>
            </div>
            <div>
              <div className="uk-text-primary mv2">
                Write Review
              </div>
              <textarea ref={reviewRef} className="uk-textarea" rows="4"/>
              <div className="mv2 uk-text-right">
                <button className="uk-button uk-button-primary" onClick={() => {
                  if (cleanlinessRating === 0) {
                    setError("service rating required")
                  } else if (ambienceRating === 0) {
                    setError("stylist rating required")
                  } else if (staffRating === 0) {
                    setError("venue rating required")
                  } else if (reviewRef.current.value === "") {
                    setError("review required")
                  }

                  let data = {
                    salon: salonId,
                    date: moment().format("YYYY") + "-" + moment().format("MM") + "-" + moment().format("DD"),
                    text: reviewRef.current.value,
                    cleanliness_score: cleanlinessRating,
                    ambience_score: ambienceRating,
                    staff_score: staffRating
                  }
                  axios.post(URLS.salonReviewsUrl(salonId), data, {
                    headers: {
                      Authorization: "Token " + userCtx.token
                    }
                  }).then(resp => {
                    setAmbienceRating(0)
                    setCleanlinessRating(0)
                    setStaffRating(0)
                    reviewRef.current.value=""
                    updateReviews()
                  }).catch(error => {
                    console.log(error)
                  })
                }}>
                  Submit
                </button>
              </div>
            </div>
          </>
          : <div className="mv2 uk-text-center">
            <button className="uk-button uk-button-primary" onClick={() => {
              setError("please login first")
            }}>
              Submit
            </button>
          </div>
      }
    </>
  )
}

export default SubmitReview
