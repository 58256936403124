import React from "react"
import boyImg from "../../images/boy.svg"
import girlImg from "../../images/girl.svg"

const ReviewListElement = ({review}) => {
    // console.log(review);

    return (
    <>
    <article className="uk-comment uk-comment-primary uk-margin">
        <header className="uk-comment-header">
            <div className="uk-grid uk-grid-medium uk-flex-middle" data-uk-grid>
                <div className="uk-width-auto">
                    <img className="uk-comment-avatar" src={boyImg} width="60" height="60" alt=""/>
                </div>
                <div className="uk-width-expand">
                    <h4 className="uk-comment-title uk-margin-remove">{(review.user?review.user.username:"Annonimus")}</h4>
                    <ul className="uk-comment-meta uk-subnav uk-subnav-divider uk-margin-remove-top">
                        <li>{review.date}</li>
                        {/* <li><a href="#">Reply</a></li> */}
                    </ul>
                </div>
            </div>
        </header>
        <div className="uk-comment-body">
            <p>{review.text}</p>
        </div>
    </article>
    {/* <h4>Username: {review.user.username}</h4>
    <p>{review.text}</p>
    <p>{review.date}</p>

    <div>Cleanliness score: {review.cleanliness_score}</div>
    <div>Ambience score: {review.ambience_score}</div>
    <div>Staff score: {review.staff_score}</div> */}
    </>
    )
}

export default ReviewListElement;