import React, { useContext, useEffect, useState } from "react"
import { cartContext } from "../context/cartContext/cartContext"
import { window } from "browser-monads"
import axios from "axios"
import { URLS } from "../config/urls"
import { getDistanceFromLatLonInKm, GoogleMapsURLToEmbedURL, showError } from "../uitlity/Utility"
import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import salonCover from "../images/salon-page/salon-cover.jpg"
import ReviewList from "../components/reviews/review-list"
import SubmitReview from "../components/submitReview/SubmitReview"
import Cart from "../components/cart/Cart"
import Footer from "../components/footer/footer"

const SalonPage = (
  // { pageContext: { salon } }
) => {
  const cartCtx = useContext(cartContext)
  const [url, setUrl] = useState(window.location.href)
  const [reviews, setReviews] = useState([])
  const [services, setService] = useState([1,2,3,4,5])
  const [packages, setPackages] = useState([1,2,3,4,5])
  const [distance, setDistance] = useState(null)
  const [salonMembers, setSalonMembers] = useState([])
  const USER_TYPE = ["ALL", "MALE", "FEMALE", "BABY"]


  const updateReview = () => {
    axios.get(URLS.salonReviewsUrl(1)).then(({ data }) => {
      setReviews(data)
    })
  }

  useEffect(() => {
    window.navigator.geolocation.getCurrentPosition(({ coords }) => {
      setDistance(getDistanceFromLatLonInKm(coords.latitude, coords.longitude, 0, 0))
    })
    // axios.get(URLS.salonMembersUrl(salon.id)).then(({ data: { results } }) => {
    //   setSalonMembers(results)
    // })
    axios.all([
      axios.get(URLS.salonReviewsUrl(1)),
      axios.get(URLS.salonServicesUrl(1)),
      axios.get(URLS.salonPackages(1))
    ]).then(axios.spread((...responses) => {
      responses[2].data.results.forEach(singlePackage => {
        singlePackage.services = responses[1].data.results.filter(({ id: serviceId }) => {
          return singlePackage.services.includes(serviceId)
        })
      })
      //debugger
      setReviews(responses[0].data)
      setService(responses[1].data.results)
      setPackages(responses[2].data.results)
    }))
  }, [])

  // useEffect(() => {
  //   setUrl(window.location.href)
  // }, [window.location.href])

  const meta = {
    title: "Name",
    image: "",
    description: "Brand Name"
  }
  // debugger

  return (
    <Layout meta={meta}>
      <SEO title="Salon Detail"/>
      <main>
        <div className="uk-section">
          <div className="uk-container uk-container-medium">
            <div className="uk-card uk-card-default pa4">
              <div className="flex justify-between flex-column flex-row-ns">
                <div>
                  <div className="uk-text-lead pb0 mb0">
                    {"Salon Brand Name"} - {"Salon Name"}
                  </div>
                  <div className="uk-text-meta pt0 mt0">
                    {"Location"}
                  </div>
                </div>
                <div>
                  <a
                    // href={salon["location_url"]}
                    target="_blank" className="uk-button uk-button-small uk-button-secondary">
                    View on map
                  </a>

                  {distance && <div className="uk-text-meta">
                    {Math.floor(distance)} kms away
                  </div>}
                </div>
              </div>
              <div className="w-100 mt4">
                <img src={salonCover} className="w-100"  style={{maxHeight: "400px"}}
                     alt="" srcset=""/>
              </div>
              <div className="w-100 uk-text-center">
                <div className="uk-button-group" style={{ marginTop: "-20px" }}>
                  <a href="#about-salon" className="uk-button uk-button-secondary about-us-button">About Us</a>
                  <a href="#reviews" className="uk-button uk-button-secondary reviews-button">Reviews</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-section">
          <div className="uk-container uk-container-medium">
            <div className="flex flex-column flex-row-ns">

              <div className="w-100 w-70-ns pr5-ns">
                <div className="popular-services-container">
                  <h2 className="uk-text-primary pb0 mb0">
                    Popular Services
                  </h2>
                  <hr className="w-100"/>
                  {services && services.map(service => {
                    return (
                      <>
                        <div className="service-card">
                          <div className="uk-text-bold uk-text-lead">
                            {"Service Name"}
                          </div>
                          <div className="flex flex-row-ns justify-between items-center">
                            <div className="w-80 w-50-ns">
                              <p className="uk-text-small mb0">
                                {"Service Description"}
                              </p>
                              <div className="uk-hidden@s mt2">
                                <div>
                                  <span className="uk-margin-small-right">
                                  <span className="uk-margin-small-right color-green"
                                        uk-icon="icon: clock"></span> {"Time Duration"}
                                  </span>
                                  <span>
                                  <span className="uk-margin-small-right color-green"
                                        uk-icon="icon: tag"></span> ₹ {"Price"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="uk-visible@s">
                              <span className="uk-margin-small-right">
                                <span className="uk-margin-small-right" uk-icon="icon: clock"></span>
                                {"Service Duration"}
                              </span>
                              <span>
                                <span className="uk-margin-small-right" uk-icon="icon: tag"></span>
                                ₹ {"Service Price"}
                              </span>
                            </div>
                            <div>
                              <button
                                // onClick={() => {
                                //   if (!cartCtx.addServiceToCart(service))
                                //     showError("Service from other saloon allready existes please clear it first")
                                // }}
                                className="uk-button uk-button-secondary uk-button-small">
                                <span className="uk-hidden@s" uk-icon="icon: cart"></span>
                                <span className="uk-visible@s">
                                  Add to Basket
                                </span>
                              </button>
                            </div>
                            <hr/>
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
                <div className="popular-services-container mt5">
                  <h2 className="uk-text-primary pb0 mb0">
                    All Services
                  </h2>
                  <hr className="w-100"/>

                  <div>
                    <div data-uk-filter="target: .js-filter">

                      <ul className="uk-subnav uk-subnav-pill">
                        <li data-uk-filter-control=".tag-all"><a href="#">All</a></li>
                        <li data-uk-filter-control=".tag-male"><a href="#">Men</a></li>
                        <li data-uk-filter-control=".tag-female"><a href="#">Women</a></li>
                        <li data-uk-filter-control=".tag-baby"><a href="#">Kids</a></li>
                      </ul>

                      <ul className="js-filter uk-list">
                        {services && services.map(service => {
                          return (
                            <>
                              <li className={"tag-all"}>
                                <div className="service-card">
                                  <div className="uk-text-bold" style={{ fontSize: "1.2rem", color: "#333" }}>
                                    {"Service Name"}
                                  </div>
                                  <div className="flex flex-row-ns justify-between items-center">
                                    <div className="w-80 w-50-ns">
                                      <p className="uk-text-small mb0">
                                        {"Service Description"}
                                      </p>
                                      <div className="uk-hidden@s mt2">
                                        <div>
                                          <span className="uk-margin-small-right">
                                          <span className="uk-margin-small-right color-green"
                                                uk-icon="icon: clock"></span> {"Service Duration"}
                                          </span>
                                          <span>
                                          <span className="uk-margin-small-right color-green"
                                                uk-icon="icon: tag"></span> ₹ {"Service Duration"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="uk-visible@s">
                                      <span className="uk-margin-small-right">
                                        <span className="uk-margin-small-right" uk-icon="icon: clock"></span>
                                        {"Service Time"}
                                      </span>
                                      <span>
                                        <span className="uk-margin-small-right" uk-icon="icon: tag"></span>
                                        ₹ {"Service Price"}
                                      </span>
                                    </div>
                                    <div>
                                      <button
                                        // onClick={() => {
                                        //   if (!cartCtx.addServiceToCart(service))
                                        //     showError("Service from other saloon allready existes please clear it first")
                                        // }}
                                        className="uk-button uk-button-secondary uk-button-small">
                                        <span className="uk-hidden@s" uk-icon="icon: cart"></span>
                                        <span className="uk-visible@s">
                                          Add to Basket
                                        </span>
                                      </button>
                                    </div>
                                    <hr/>
                                  </div>
                                </div>
                                {/* <div className="service-card">
                                  <div className="uk-text-bold">
                                    {service.name}
                                  </div>
                                  <div className="flex flex-column flex-row-ns justify-between items-center">
                                    <p className="uk-text-small uk-text-muted w-100 w-50-ns">
                                      {service.description}
                                    </p>
                                    <div>
                        <span className="uk-text-muted mh2">
                        {service.time_duration}
                        </span>
                                      <span className="uk-text-muted mh2">
                        ₹ {service.price}
                        </span>
                                    </div>
                                    <div>
                                      <button
                                        onClick={() => {
                                          if (!cartCtx.addServiceToCart(service))
                                            showError("Service from other saloon allready existes please clear it first")
                                        }}
                                        className="uk-button uk-button-secondary">
                                        Add to Basket
                                      </button>
                                    </div>
                                  </div>
                                </div> */}
                              </li>
                            </>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="popular-services-container mt5">
                    <h2 className="uk-text-primary pb0 mb0">
                      Packages
                    </h2>
                    <hr className="w-100"/>
                    {
                      packages.map(singlePackage => <>
                        {/* <p>{singlePackage.name}</p>
                        <p>{singlePackage.price}</p>
                        <p>{singlePackage.description}</p>
                        <p>{singlePackage["time_duration"]}</p>
                        <p>Services includes:</p>
                        {
                          singlePackage.services.map(service => (<>
                            <h3>{service.name}</h3>
                          </>))
                        }
                        <button onClick={() => cartCtx.addPackageToCart(singlePackage)}>Add to basket</button> */}
                        <div className="service-card">
                          <div className="uk-text-bold" style={{ fontSize: "1.2rem", color: "#333" }}>
                            {"Package Name"}
                          </div>
                          <div className="flex flex-row-ns justify-between items-center-ns items-end">
                            <div className="w-80 w-50-ns">
                              <p className="uk-text-small">
                                {"Package Description"}
                              </p>
                              <h6 className="uk-text-bold mv0">Services Included:</h6>
                              <ul className="uk-list uk-list-hyphen uk-list-collapse mt2">
                                {/*{*/}
                                {/*  singlePackage.services.map(service => (<>*/}
                                {/*    <li className="uk-text-small">{service.name}</li>*/}
                                {/*  </>))*/}
                                {/*}*/}
                              </ul>
                              <div className="uk-hidden@s mt2">
                                <div>
                                <span className="uk-margin-small-right">
                                <span className="uk-margin-small-right color-green" uk-icon="icon: clock"></span>
                                  {"Time Duration"}
                                </span>
                                  <span>
                                <span className="uk-margin-small-right color-green" uk-icon="icon: tag"></span>
                                ₹ {"Price"}
                                </span>
                                </div>
                              </div>
                            </div>
                            <div className="uk-visible@s">
                            <span className="uk-margin-small-right">
                              <span className="uk-margin-small-right" uk-icon="icon: clock"></span>
                              {"Time Duration"}
                            </span>
                              <span>
                              <span className="uk-margin-small-right" uk-icon="icon: tag"></span>
                              ₹ {"Price"}
                            </span>
                            </div>
                            <div>
                              <button
                                // onClick={() => {
                                //   if (!cartCtx.addPackageToCart(singlePackage))
                                //     showError("Service from other saloon allready existes please clear it first")
                                // }}
                                className="uk-button uk-button-secondary uk-button-small">
                                <span className="uk-hidden@s" uk-icon="icon: cart"></span>
                                <span className="uk-visible@s">
                                Add to Basket
                              </span>
                              </button>
                            </div>
                            <hr/>
                          </div>
                        </div>
                      </>)

                    }

                  </div>
                </div>
                <div id="about-salon" className="about-salon-container pt5">
                  <h2 className="uk-text-primary pb0 mb0">
                    About Salon
                  </h2>
                  <hr className="w-100"/>
                  <p className="uk-text-small">
                    <p>{"Brand Info"}</p>
                  </p>
                  {salonMembers && <ul className="flex flex-wrap uk-list mb4">
                    {
                      salonMembers.map(member =>
                        <li className="uk-card pa2 ma2 uk-card-default">
                          <img style={{width: "100px", height: "100px"}} src={member["profile_pic"]} alt=""/>
                          <div>{member["nick_name"]}</div>
                        </li>
                      )
                    }
                  </ul>}


                    <iframe
                      style={{filter: "greyscale"}}
                      // src={(function(){return GoogleMapsURLToEmbedURL(salon["location_url"])})()}
                      width="100%" height="350" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false"
                      tabIndex="0"/>

                </div>
                <div id="reviews" className="salon-reviews-container pt5">
                  <h2 className="uk-text-primary pb0 mb0">
                    Reviews
                  </h2>
                  <hr className="w-100"/>
                  {reviews && <ReviewList reviews={reviews}/>}
                  <div className="uk-card uk-card-default pa4">
                    <SubmitReview salonId={1} updateReviews={updateReview}/>
                  </div>
                </div>
              </div>
              <div className="w-100 w-30-ns">
                <Cart/>
              </div>
            </div>
          </div>
        </div>

        {/*<h3>Services</h3>*/}
        {/*{*/}
        {/*  USER_TYPE.map((userType) => <>*/}
        {/*    <h2>{userType}</h2>*/}
        {/*    {services && services.filter(service => service["user_type"] === userType).map(service => {*/}
        {/*      return (*/}
        {/*        <>*/}
        {/*          <p>Description :{service.description}</p>*/}
        {/*          <p>Name : {service.name}</p>*/}
        {/*          <p>Price : {service.price}</p>*/}
        {/*          <p>Time- Duration : {service.time_duration}</p>*/}
        {/*          <button onClick={() => {*/}
        {/*            //debugger*/}
        {/*            if (!cartCtx.addServiceToCart(service))*/}
        {/*              showError("Service from other saloon allready existes please clear it first")*/}
        {/*          }}>Add to cart*/}
        {/*          </button>*/}
        {/*        </>*/}
        {/*      )*/}
        {/*    })}*/}
        {/*  </>)*/}
        {/*}*/}
        <Footer />


      </main>

    </Layout>
  )
}


export default SalonPage
