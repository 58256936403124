import React, { useContext, useEffect, useRef, useState } from "react"
import { cartContext } from "../../context/cartContext/cartContext"
import { navigate } from "gatsby"
import { userContext } from "../../context/userContext/userContext"
import axios from "axios"
import { base, URLS } from "../../config/urls"
import InfoModal from "../infoModal/InfoModal"

const Cart = (props) => {
  const cartCtx = useContext(cartContext)
  const userCtx = useContext(userContext)
  const couponRef = useRef(null)
  const [coupons, setCoupons] = useState([])
  const [subscriptionList, setSubscriptionList] = useState([])
  const [selectedSubscription, setSelectedSubscription] = useState(-1)

  const [error, setError] = useState(null)

  const fetchCoupons = () => {
    axios.get(URLS.fetchCoupon(), {
      headers: {
        Authorization: "Token " + userCtx.token
      }
    }).then(({ data }) => {
      setCoupons(data)
    })
  }
  const fetchSubscriptionList = () => {
    axios.get(URLS.fetchSubscriptionList(), {
      headers: {
        Authorization: "Token " + userCtx.token
      }
    }).then(({ data }) => {
      setSubscriptionList(data)
    })
  }
  const applyCoupon = () => {
    if (couponRef.current.value === "") {
      cartCtx.setCoupon(null)
      return
    }
    axios.post(URLS.verifyCoupon(), {
      "code": couponRef.current.value,
      "salon_id": cartCtx.cartValue.salon,
      "service_ids": cartCtx.cartValue.services.reduce((prev, service) => {
        return prev.concat(Array(service.persons).fill(service.id))
      }, []),
      "package_ids": cartCtx.cartValue.packages.reduce((prev, singlePackage) => {
        return prev.concat(Array(singlePackage.persons).fill(singlePackage.id))
      }, [])
    }, {
      headers: {
        Authorization: "Token " + userCtx.token
      }
    }).then(({ data }) => {
      let selectedCoupon = coupons.filter((coupon) => coupon.id === data["coupon_id"])
      cartCtx.setCoupon(selectedCoupon[0])
    }).catch(error => {
      setError("Coupon Not Applicable")
    })
  }

  useEffect(() => {
    if (userCtx.user) {
      fetchCoupons()
      fetchSubscriptionList()
    }
    if (cartCtx.cartValue.subscription) cartCtx.truncatePersons()
  }, [])

  let serviceList = []
  let packageList = []
  if (cartCtx && cartCtx.cartValue) {
    serviceList = [].concat(cartCtx.cartValue.services)
    packageList = [].concat(cartCtx.cartValue.packages)
  }
  debugger
  let charges = serviceList.length && serviceList.reduce((prev, service) => prev + service.price * service.persons, 0)
  charges = charges + (packageList.length && packageList.reduce((prev, singlePackage) => prev + singlePackage.price * singlePackage.persons, 0))
  let total = charges
  if (cartCtx.cartValue.subscription) {
    total = charges * cartCtx.cartValue.subscription["paid_months"]
  }
  let discount = 0
  let handlingCharges = 10
  if (cartCtx.cartValue.coupon) {
    let { discount: discountValue, has_upper_limit, upto, is_percentage } = cartCtx.cartValue.coupon
    if (is_percentage) {
      discount = charges * (discountValue / 100)
    } else {
      discount = discountValue
    }
    if (has_upper_limit && discount > upto) {
      discount = upto
    }
  }


  return (<>
    <InfoModal setError={setError} error={error}/>
    <div className="uk-card uk-card-default pa4" style={{ zIndex: "980" }}>
      <div className="uk-text-center">
        <h2 className="uk-text-primary">
          Your Basket
        </h2>
      </div>
      {serviceList.map(service =>
        <div className="flex mv2 justify-between items-center">
          <div className="w-50">
            <div>
              {service.name}
            </div>
            <div className="uk-text-small uk-text-muted mt0 pt0">
              {service.time_duration} minutes
            </div>
            <div className="uk-text-bold ">
              ₹ {service.price}
            </div>
          </div>
          <div className="flex flex-column items-center">
            <div className="flex items-center" style={{height: "25px"}}>
              {!cartCtx.cartValue.subscription&&<button className="uk-button uk-button-small" onClick={() => cartCtx.addPersonToService(service.id)}>+</button>}
                <p className="ph2">{service.persons}</p>
              {!cartCtx.cartValue.subscription&&<button className="uk-button uk-button-small" onClick={() => cartCtx.removePersonFromService(service.id)}>-</button>}
            </div>
            <div className="mv2" style={{ cursor: "pointer"}} onClick={() => cartCtx.removeService(service.id)}>
              Delete
            </div>
          </div>
        </div>
      )}
      {packageList.map(singlePackage =>
        <div className="flex mv2 justify-between items-center">
          <div className="w-50">
            <div>
              {singlePackage.name}
            </div>
            <div className="uk-text-small uk-text-muted mt0 pt0">
              {singlePackage.services.map(service => <p>{service.name}</p>)}
              {singlePackage.time_duration}
            </div>
          </div>
          <div className="">
            <div className="uk-text-bold ">
              ₹ {singlePackage.price}
            </div>
          </div>
          {!cartCtx.cartValue.subscription &&
          <button onClick={() => cartCtx.addPersonToPackage(singlePackage.id)}>+</button>}
          <p>{singlePackage.persons}</p>
          {!cartCtx.cartValue.subscription &&
          <button onClick={() => cartCtx.removePersonFromPackage(singlePackage.id)}>-</button>}
          <div>
            <div style={{ cursor: "pointer" }} onClick={() => cartCtx.removePackage(singlePackage.id)}>
              delete
            </div>
          </div>
        </div>
      )}
      <hr/>
      <div className="flex justify-between items-center w-100">
        <div className="w-50">
          Sub Total
        </div>
        <div className="uk-text-bold">
          ₹ {charges}
        </div>
      </div>
      <hr/>
      {(userCtx.user && (cartCtx.cartValue.services.length || cartCtx.cartValue.services.length)) ?<>
        <div>
          <p>
            Subscribe to added services
          </p>
          <div className="flex justify-between items-center">
            <div className="w-50">
              <select className="uk-select" onChange={(event) => {
                setSelectedSubscription(parseInt(event.target.selectedOptions[0].value))
              }}>
                <option value={-1}/>
                {
                  subscriptionList.map((subscription) => <option
                    value={subscription.id}>{subscription["paid_months"]} Months + {subscription["free_months"]} Moths
                    free</option>)
                }
              </select>
            </div>

            {selectedSubscription > -1 && <div className="uk-text-bold">
              ₹ <span>{charges * (subscriptionList.filter(subscription => {
              return subscription.id === selectedSubscription
            })[0]["paid_months"])}</span>
            </div>}
          </div>
          <div className="pv3 flex justify-between">
            <a href="#" className="uk-text-small uk-text-primary">12 + 4 free services</a>
            {selectedSubscription > -1 && <div className="uk-text-small uk-text-muted">
            <span>{subscriptionList.filter(subscription => {
              return subscription.id === selectedSubscription
            })[0]["paid_months"]}</span> X <span>₹ {(charges).toFixed(2)}</span>
            </div>}
          </div>
          <button className="uk-button uk-button-secondary w-100"
                  onClick={() => {
                    // debugger
                    cartCtx.truncatePersons()
                    cartCtx.setSubscription(subscriptionList.filter(subscription => subscription.id === selectedSubscription)[0])
                  }}>
            Add Subscription
          </button>
          <button className="uk-button uk-button-secondary w-100"
                  onClick={() => {
                    cartCtx.setSubscription()
                  }}>
            Remove Subscription
          </button>
        </div>
        <hr/>
      </>:null}
      <div>
        <div className="flex justify-between items-center">
          <div>
            Total
          </div>
          <div className="uk-text-bold">
            ₹ {total.toFixed(2)}
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div>
            Discount
          </div>
          <div className="uk-text-bold">
            - ₹ {discount.toFixed(2)}
          </div>
        </div>
        {(userCtx.user && (cartCtx.cartValue.services.length || cartCtx.cartValue.services.length))? <div className="mt3">
          <input ref={couponRef} type="text" className="uk-input" placeholder="discount coupon"/>
          <button onClick={applyCoupon}>Apply
          </button>
          <select name="coupons" id="coupons" onClick={(e) => {
            couponRef.current.value = e.target.selectedOptions[0].value
          }}>
            <option value=""></option>
            {coupons.map((coupon) => <option value={coupon.code}>{coupon.code}</option>)}
          </select>
        </div>:null
        }
      </div>
      <hr/>
      {(cartCtx.cartValue.services.length || cartCtx.cartValue.services.length)?
        <><div>
        <div className="flex justify-between">
          <div className="uk-text-muted">
            Total Charges
          </div>
          <div className="uk-text-muted">
            ₹{(total + handlingCharges - discount).toFixed()}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="uk-text-muted">
            Taxes
          </div>
          <div className="uk-text-muted">
            ₹{((total + handlingCharges - discount) * 0.18).toFixed(2)}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="uk-text-muted">
            Handeling Fees ₹{handlingCharges.toFixed(2)}
          </div>
          <div className="uk-text-muted">
          </div>
        </div>
      </div>
        <hr/>
        </>:null
      }
      <div>
        <div className="flex justify-between mb3">
          {(cartCtx.cartValue.services.length||cartCtx.cartValue.packages.length) ?
            <>
          <div className="uk-text-muted">
            Total Charges
          </div>
            <div className="uk-text-muted">
            ₹ {((total + handlingCharges - discount) * 1.18).toFixed(2)}
            </div>
          </>:null}
        </div>
        <button className="uk-button uk-button-primary w-100"
                onClick={() => {
                  // if (cartCtx.cartValue.salon)
                    navigate("/bookingSchedule")
                  // else setError("Please add service to cart first")
                    }}
        >
          Book a Time Slot
        </button>
        <div className="uk-margin">
        <button className="uk-button uk-button-secondary w-100"
                onClick={cartCtx.emptyCart}
        >
          Clear Cart
        </button>
        </div>
      </div>
    </div>
  </>)

}

export default Cart
