import React, { useState, useEffect } from "react"
import ReviewListElement from "./review-list-element"

const ReviewList = ({ reviews }) => {
  const [ambienceScore, setAmbienceScore] = useState(0)
  const [staffScore, setStaffScore] = useState(0)
  const [cleanlinessScore, setCleanlinessScore] = useState(0)

  useEffect(() => {
    let averageAmbienceScore = 0
    let averageStaffScore = 0
    let averageCleanlinessScore = 0

    reviews.forEach(review => {
      averageCleanlinessScore += review.cleanliness_score
      averageAmbienceScore += review.ambience_score
      averageStaffScore += review.staff_score
    })

    if(reviews.length) {
        averageAmbienceScore /= reviews.length
        averageStaffScore /= reviews.length
        averageCleanlinessScore /= reviews.length
    
        setAmbienceScore(averageAmbienceScore)
        setStaffScore(averageStaffScore)
        setCleanlinessScore(averageCleanlinessScore)
    }
  }, [reviews])

  return (
    <>
      <div>
        <div className="uk-text-large">
          Average Rartings
        </div>
        <div className="flex flex-column flex-row-ns justify-between items-center mv2">
          <div className="w-100 w-30-ns">
          Ambience Score:
          </div> 
          <div className="w-100 w-70-ns flex justify-end">
            <div className="uk-text-right mr3">
              {ambienceScore.toFixed(1)} / 5
            </div>
            <div className="w-80">
            <progress className="uk-progress mv2" value={ambienceScore} max="5" style={{height: "5px"}}></progress>
          </div>
          </div>
        </div>
        <div className="flex flex-column flex-row-ns justify-between items-center mv2">
          <div className="w-100 w-30-ns">
          Staff Score:
          </div> 
          <div className="w-100 w-70-ns flex justify-end">
            <div className="uk-text-right mr3">
            {staffScore.toFixed(1)} / 5
            </div>
            <div className="w-80">
            <progress className="uk-progress mv2" value={staffScore} max="5" style={{height: "5px"}}></progress>
          </div>
          </div>
        </div>
        <div className="flex flex-column flex-row-ns justify-between items-center mv2">
          <div className="w-100 w-30-ns">
          Cleanliness Score:
          </div> 
          <div className="w-100 w-70-ns flex justify-end">
            <div className="uk-text-right mr3">
            {cleanlinessScore.toFixed(1)} / 5
            </div>
            <div className="w-80">
            <progress className="uk-progress mv2" value={cleanlinessScore} max="5" style={{ height: "5px" }}/>
          </div>
          </div>
        </div>
      </div>

      {reviews.map(review => (
        <ReviewListElement review={review}/>
      ))}
    </>
  )
}

export default ReviewList
