import React, { useState } from "react"
import "./ratings.css"

const RatingComponent = ({setValue,id}) => {
    const [rating, setRating] = useState(1);

    const preSetValue = num => {
        setRating(num);
        setValue(6-num);
    }

    return (
        <div className="Ratingform">
            <div id="wrapper">
                <form>
                    <p className="clasificacion">
                        {[1,2,3,4,5].map(e => (
                            <>
                                <input id={`radio${e}${id}`} type="radio" name={"rating" + (e)} value={`${e}`} onClick={()=>preSetValue(e)} />
                                <label htmlFor={"radio"+(e)+id} className={(rating == e) ? 'active-label' : ''}>&#9733;</label>
                            </>
                        ))}
                    </p>
                </form>
            </div>
        </div>
    );
}

export default RatingComponent


