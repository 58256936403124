import React, { useEffect } from "react"

const InfoModal = ({error,setError})=>{

  useEffect(()=>{
    if(error){
      window.UIkit.notification(error,{
        status:"danger",
        pos: "top-center"
      });
      setError(null)
    }
  })

 return null;

}

export default InfoModal